<template>
  <div>
    <input type="text" v-model="data.label" placeholder="Überschrift ..." />
    <textarea
      type="text"
      v-model="data.description"
      placeholder="Beschreibung (optional) ..."
    />
    <required />
    <columnWidth />
  </div>
</template>
<script>
export default {
  components: {
    columnWidth() {
      return import("./width.vue");
    },
    required() {
      return import("./required.vue");
    }
  },
  props: {
    data: Object
  },
  data() {
    return {};
  },
  watch: {
    "data.description"() {
      this.setSave();
    },
    "data.label"() {
      this.setSave();
    }
  },
  computed: {},
  mounted() {},
  methods: {
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.form[this.$store.state.form.edit] = this.data;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
input, textarea
  width: 100%
  padding: 10px
  box-sizing: border-box
  border: 1px solid $success
  &:focus
    outline: none
textarea
  margin-top: 10px
  height: 200px
</style>
